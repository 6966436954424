<template>
    <v-tabs vertical
            icons-and-text
            class="company--tabs__report"
    >
        <v-tab class="mt-10">
            {{$t('admin.reports.tabs.lastGamesAndQuestionsByDay')}}
        </v-tab>
        <v-tab>
            {{$t('admin.reports.tabs.accessToSystemMonth')}}
        </v-tab>
        <v-tab>
            {{$t('admin.reports.tabs.accessToSystemHabits')}}
        </v-tab>


        <v-tab-item>
            <v-card flat>
                <gamesAndQuestionsHistory
                    :company-id="companyId"
                />
            </v-card>
        </v-tab-item>

        <v-tab-item>
            <v-card flat>
                <usersAccessHistory
                    :company-id="companyId"
                />
            </v-card>
        </v-tab-item>

        <v-tab-item>
            <v-card flat>
                <access-habits
                    :company-id="companyId"
                />
            </v-card>
        </v-tab-item>

    </v-tabs>
</template>

<script>

export default {
    name: "indexReport",
    props: {
        companyId: {
            type: Number,
            required: true
        }
    },
    components: {
        gamesAndQuestionsHistory: () => import("@/components/admin/companies/tabs/reports/gamesAndQuestionsHistory"),
        usersAccessHistory: () => import("@/components/admin/companies/tabs/reports/usersAccessHistory"),
        accessHabits: () => import("@/components/admin/companies/tabs/reports/accessHabits")

    }
}
</script>

<style scoped lang="scss">

.company--tabs__report{
    .v-tab--active{
        background-color: #F0AB003D;
    }
}
</style>
