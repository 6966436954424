<template>
    <v-row>
        <v-col cols="12">
            <base-card>
                <v-card-title class="my-0 py-0">
                    <div class="d-flex justify-space-between flex-wrap">
                        <v-btn
                            class="ma-3"
                            dark
                            color="primary"
                            @click="createItem"
                        >
                            <v-icon>mdi-plus</v-icon>
                            {{ $t('admin.companyRanking.add') }}
                        </v-btn>
                    </div>
                </v-card-title>
                <v-card-title>
                    {{ $t('admin.companyRanking.title') }}
                    <v-spacer />
                    <v-spacer />
                    <v-spacer />
                    <v-spacer />

                    <v-text-field
                        v-model="search"
                        :append-icon="!search ? 'mdi-magnify' : undefined"
                        clearable
                        :label="$t('common.search')"
                        single-line
                        hide-details
                    />
                </v-card-title>

                <!-- :pagination.sync="pagination" -->
                <v-data-table
                    :options.sync="options"
                    :headers="headers"
                    :footer-props="{ 'items-per-page-options': [5, 10, 30, 50, -1] }"
                    :items="items"
                    class="elevation-1"
                    :sort-by="sortby"
                    :multi-sort="true"
                    :search="search"
                    :custom-filter="filterTableItems"
                    @click:row="editItem"
                >
                    <template #item.id="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>

                    <!--                    <template #item.company_id="{ value }">
                        <div :inner-html.prop="(companies.nameMap[value] || value) | highlight(search)" />
                    </template>-->

                    <template #item.game_id="{ value }">
                        <div :inner-html.prop="(games.map[value] || value) | highlight(search)" />
                    </template>

                    <template #item.title_es="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>
                    <template #item.title_en="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>

                    <template v-slot:item.created_at="{ value }">
                        <div :inner-html.prop="dateHelper.getDateTimeTableFormat(value) | highlight(search)" />
                    </template>
                    <template #item.updated_at="{ value }">
                        <div :inner-html.prop="dateHelper.getDateTimeTableFormat(value) | highlight(search)" />
                    </template>


                    <template v-slot:item.actions="{ item }">

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    small
                                    class="mr-2"

                                    v-bind="attrs"
                                    @click.stop="editItem(item)"
                                    v-on="on"
                                >
                                    mdi-pencil
                                </v-icon>
                            </template>

                            <span>{{ $t('common.edit') }}</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    small
                                    v-bind="attrs"
                                    @click.stop="deleteItem(item)"
                                    v-on="on"
                                >
                                    mdi-delete
                                </v-icon>
                            </template>

                            <span>{{ $t('common.delete') }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:no-data>
                        <v-btn
                            color="primary"
                            @click="fetchData"
                        >
                            {{ $t('admin.common.resetData') }}
                        </v-btn>
                    </template>
                </v-data-table>
            </base-card>

            <rankingItemCreate
                v-if="dialog"
                :dialog="dialog"
                :item="itemSelected"
                :companyId="companyId"
                :games="games.list"
                :url-main="urlMain"
                @update:dialog="dialog = $event"
                @update:table="fetchData"
            />

            <confirm-dialog
                ref="confirm"
            />
        </v-col>
    </v-row>
</template>

<script>

import ConfirmDialog from '@/components/common/ConfirmDialog'
import listAdminMixin from "@/mixins/listAdminMixin";
import rankingItemCreate from "@/components/admin/companies/tabs/ranking/rankingItemCreate";
import CompaniesAPIService from "@/services/CompaniesAPIService";
import GamesAPIService from "../../../../../services/GamesAPIService";



export default {
    components: { rankingItemCreate, ConfirmDialog },
    mixins: [listAdminMixin],
    props:{
        companyId: Number
    },
    data: function () {
        return {
            urlMain: 'admin/games/companyRankings',
            /* dialog: false, */
            headers: [
                {
                    text: 'Id',
                    align: 'start',
                    sortable: true,
                    value: 'id'
                },
                /*{
                    text: this.$t('admin.companyRanking.company'),
                    value: 'company_id'
                },*/
                {
                    text: this.$t('admin.companyRanking.game'),
                    value: 'game_id'
                },
                {
                    text: this.$t('admin.companyRanking.title_es'),
                    value: 'title_es'
                },
                {
                    text: this.$t('admin.companyRanking.title_en'),
                    value: 'title_en'
                },
                {
                    text: this.$t('admin.common.createdAt'),
                    value: 'created_at'
                },
                {
                    text: this.$t('admin.common.updatedAt'),
                    value: 'updated_at'
                },
                // { text: "Role", value: "role_id" },
                {
                    text: this.$t('admin.common.actions'),
                    value: 'actions',
                    sortable: false
                }
            ],
            /*companies: {
                nameMap: {},
                list: []
            },*/
            games: {
                map: {},
                list: []
            }
        }
    },
    computed: {

    },
    async created() {
        // await this.fetchCompanies();
        await this.fetchGames();
    },
    methods: {
        /*async fetchCompanies(){
            try {
                const result = await CompaniesAPIService.getCompanies();

                this.companies.list = result.data.data.map(item => ({id: +item.id, name: item.attributes.name }));

                const nameMap = {};
                this.companies.list.forEach(item => {
                    nameMap[item.id] = item.name;
                });
                this.companies.nameMap = nameMap;

            } catch (e) {
                if(e.name !== 'TypeError')
                    this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },*/
        async fetchGames(){
            try {
                const companyId = this.companyId;
                const includeWithoutCompany = true;

                const controller = new AbortController();
                const signal = controller.signal;
                const result = await GamesAPIService.getGamesFromCompany({ companyId, includeWithoutCompany, signal });

                if(this.games.list.length === result.data.games.length) return;

                this.games.list = result.data.games.map(item => ({id: +item.id, name: item.name || '--', description: item.description }));

                const map = {};
                this.games.list.forEach(item => {
                    map[item.id] = item.name;
                });
                this.games.map = map;

            } catch (e) {
                if(e.name !== 'TypeError')
                    this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        initialize($data) {
            this.items = $data.rankings ?? [];

            // this.pagination.totalItems = $data?.meta?.page?.total ?? 0;
        },
        infoItem(item) {
            this.$router.push(`/app/admin/games?type=${item.id}`);
        },
        createItem() {
            this.fetchGames();
            this.dialog = true;
        // this.$router.push('/app/admin/games/classifiers/card_types/create');
        }
    }
}
</script>
