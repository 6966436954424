<template>
    <v-card>
        <v-tabs v-model="tab">
            <v-tab key="info">
                {{$t('admin.companies.tabs.basicInfo')}}
            </v-tab>
            <v-tab
                v-if="item"
                key="post"
            >
                {{$t('admin.companies.tabs.posts')}}
            </v-tab>
            <v-tab
                v-if="item"
                key="ranking"
            >
                {{$t('admin.companies.tabs.ranking')}}
            </v-tab>
            <v-tab
                v-if="item"
                key="points"
            >
                {{$t('admin.companies.tabs.points')}}
            </v-tab>
            <v-tab
                v-if="item"
                key="games"
            >
                {{$t('admin.companies.tabs.activities')}}
            </v-tab>
            <v-tab
                v-if="item"
                key="reports"
            >
                {{$t('admin.companies.tabs.reports')}}
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item
                key="info"
            >
                <basicInfo
                    :item="item"
                    :company-id="+companyId"
                    @update:companyId="companyId = $event"
                    @update:item="item = $event"
                />
            </v-tab-item>

            <v-tab-item
                key="post"
            >
                <posts-component
                    v-if="item"
                    :company-id="+companyId"
                />
            </v-tab-item>

            <v-tab-item
                key="ranking"
            >
                <ranking-items
                    v-if="item"
                    :company-id="+companyId"
                />
            </v-tab-item>

            <v-tab-item
                key="points"
            >
                <points-users
                    v-if="item"
                    :company-id="+companyId"
                />
            </v-tab-item>

            <v-tab-item
                key="games"
            >
                <gamesList
                    v-if="item"
                    :company-id="+companyId"
                />
            </v-tab-item>

            <v-tab-item
                key="reports"
            >
                <index-report
                    v-if="item"
                    :company-id="+companyId"
                />
            </v-tab-item>

        </v-tabs-items>

        <v-card-actions
            v-if="tab >= 1"
            class="pa-6">
            <v-spacer />

            <c-btn

                :outlined="false"
                :text="false"
                :dark="true"
                @click="$router.go(-1)"
            >
                {{ $t('common.endProcess') }}
            </c-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import PostsComponent from '@/components/admin/posts/postsList'
import basicInfo from "@/components/admin/companies/tabs/basicInfo";
import CompaniesAPIService from "@/services/CompaniesAPIService";
import rankingItems from "@/components/admin/companies/tabs/ranking/rankingItems";
import pointsUsers from "@/components/admin/companies/tabs/points/pointsUsers";
import createGameCompetition from "@/components/admin/companies/tabs/points/createGameCompetition";
import gamesList from "@/components/admin/companies/tabs/games/gamesList";
import indexReport from "@/components/admin/companies/tabs/reports/indexReport";


export default {
    components: { PostsComponent, basicInfo, rankingItems, pointsUsers, createGameCompetition, gamesList, indexReport},
    data: function () {
        return {
            companyId: null,
            item: null,
            tab: null
        }
    },
    async created() {
        const itemParam = this.$route.params.item;

        this.companyId = this.$route.params.id;

        if (itemParam) {
            this.item = itemParam;
        } else if (Number.isInteger(+this.companyId)) {
            this.item = (await this.fetchItem(this.companyId)).data;
        }
    },
    methods: {
        async fetchItem(companyId) {
            try {
                const controller = new AbortController();
                const signal = controller.signal;

                const item = await CompaniesAPIService.getCompany({companyId, signal});

                return item.data;
            } catch (e) {
                if(e.name !== 'TypeError')
                    this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'));

                return null;
            }
        }
    },
    destroyed() {

    }
}
</script>

<style >

</style>
