<template>
    <v-container>

        <div class="d-flex flex-wrap justify-start mb-2">
            <v-autocomplete
                class="title-post mb-4"
                v-model="gameSelected"
                :items="games"
                item-text="name"
                item-value="id"
                return-object
                style="max-width: 500px"
                hide-details
            />
            <v-btn
                class="ma-2"
                dark
                color="primary"
                @click="createExternalCompetition"
            >
                <v-icon>mdi-plus</v-icon>
                {{ 'Tabla de Resultados'}}
            </v-btn>
        </div>

        <div>
            <v-data-table
                :headers="headers"
                :items="ranking"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                hide-default-footer
                class="elevation-1"
                :search="search"
                :custom-filter="filterTableItems"
                @page-count="pageCount = $event"
                @click:row="editItem"
            >
                <template v-slot:top>
                    <div class="d-flex flex-wrap">
                        <v-btn
                            v-show="gameSelected"
                            class="ma-2"
                            dark
                            color="primary"
                            @click="createPlayer"
                        >
                            <v-icon>mdi-plus</v-icon>
                            {{ 'Puntuación'}}
                        </v-btn>

                        <import-file
                            :urlSubmit="`admin/games/statistic/pointsFileImport/${companyId}/${gameSelected?.id}`"
                            :callbackOnImport="afterImportPoints"
                        />


                        <v-spacer/>

                        <v-text-field
                            class="mx-2"
                            style="max-width: 300px"
                            v-model="search"
                            :append-icon="!search ? 'mdi-magnify' : undefined"
                            clearable
                            :label="$t('common.search')"
                            single-line
                            hide-details
                        />
                    </div>
                </template>

                <template v-slot:item.points="{ item }">
                    <v-chip
                        :color="getPointsColor(item)"
                        dark
                    >
                        {{ getPointsText(item) }}
                    </v-chip>
                </template>

                <template v-slot:item.timeT="{ item }">
                    <span v-text="getTimeForHumansString(item.timeT)"></span>
                </template>

                <template v-slot:item.actions="{ item }">

                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                small
                                class="mr-2"

                                v-bind="attrs"
                                @click.stop="editItem(item)"
                                v-on="on"
                            >
                                mdi-pencil
                            </v-icon>
                        </template>

                        <span>{{ $t('common.edit') }}</span>
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                small
                                v-bind="attrs"
                                @click.stop="deleteItem(item)"
                                v-on="on"
                            >
                                mdi-delete
                            </v-icon>
                        </template>

                        <span>{{ $t('common.delete') }}</span>
                    </v-tooltip>
                </template>

            </v-data-table>

            <div class="d-flex pt-2 flex-wrap justify-space-between">
                <v-text-field
                    style="max-width: 6rem;"
                    :value="itemsPerPage"
                    :label="$t('games.ranking.itemsPerPages')"
                    type="number"
                    min="3"
                    max="300"
                    @input="itemsPerPage = parseInt($event, 10)"
                ></v-text-field>

                <v-pagination
                    v-model="page"
                    :length="pageCount"
                ></v-pagination>

            </div>

        </div>

        <pointsUserCreate
            v-if="pointsUserDialog"
            :dialog="pointsUserDialog"
            :item="pointsUserSelected"
            :gameId="gameSelected.id"
            :companyId="companyId"
            @update:dialog="pointsUserDialog = $event"
            @update:table="getRanking"
        />

        <confirm-dialog
            ref="confirm"
        />

        <create-game-competition ref="createGameCompetition" :companyId="companyId"/>
    </v-container>
</template>

<script>

import dateTimeMixin from '@/mixins/dateTimeMixin'
import RankingAPIService from "@/services/RankingAPIService";
import GamesAPIService from "@/services/GamesAPIService";
import axios from "@/plugins/axios";
import pointsUserCreate from "./pointsUserCreate";
import itemPointsMixin from "@/mixins/itemPointsMixin";

export default {
    name: "pointsUsers",
    mixins:[dateTimeMixin, itemPointsMixin],
    components: {
        pointsUserCreate,
        createGameCompetition: () => import("@/components/admin/companies/tabs/points/createGameCompetition"),
        importFile: () => import("@/components/common/ImportFile")
    },
    props:{
        companyId: {
            type: Number,
            required: true
        }
    },
    data: function () {
        return {
            games: [],
            gameSelected: null,
            pointsUserSelected: null,
            pointsUserDialog: false,
            search: '',
            ranking: [],
            page: 1,
            pageCount: 0,
            itemsPerPage: 50,
            headers: [
                {
                    text: 'Id',
                    align: 'start',
                    sortable: true,
                    value: 'id'
                },
                { text: this.$t('admin.users.email'), value: 'email', align: 'start' },
                {
                    text: this.$t('games.ranking.name'),
                    align: 'start',
                    value: 'name'
                },
                { text: this.$t('games.ranking.lastname'), value: 'lastname', align: 'start' },
                { text: this.$t('games.ranking.points'), value: 'points', align: 'end' },
                { text: this.$t('games.ranking.time'), value: 'timeT', align: 'end' },
                {
                    text: this.$t('admin.common.actions'),
                    value: 'actions',
                    sortable: false,
                    align: 'end'
                }
            ]
        }
    },
    async created() {
        this.$store.dispatch('loading/start');
        await this.getGamesFromCompany();
        this.$store.dispatch('loading/end');
    },
    watch:{
        gameSelected:{
            async handler(value){
                const gameId = value?.id;

                await this.getRanking(gameId);
            }
        }
    },
    methods: {
        async getGamesFromCompany() {
            try {
                const companyId = this.companyId;
                const includeWithoutCompany = true;

                const controller = new AbortController();
                const signal = controller.signal;
                const data = await GamesAPIService.getGamesFromCompany({ companyId, includeWithoutCompany, signal });

                this.initialize(data.data);

            } catch (e) {
                if(e.name !== 'TypeError')
                    this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'));
            }
        },
        async getRanking(gameId) {
            this.$store.dispatch('loading/start');

            gameId = gameId || this.gameSelected?.id || '';
            const companyId = this.companyId;
            try {
                const controller = new AbortController();
                const signal = controller.signal;

                const data = await RankingAPIService.getRankingUserDetails( { companyId, gameId, signal });

                this.initialize(data.data);

            } catch (e) {
                if(e.name !== 'TypeError')
                    this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'));
            }

            this.$store.dispatch('loading/end');
        },
        initialize(data) {

            if(data.games){
                this.games = data.games;
                this.gameSelected = data.games.length ? data.games[0] : null;
            }

            if(data.ranking)
                this.ranking = data.ranking;

        },
        editItem(item) {
            this.pointsUserSelected = item;
            this.pointsUserDialog = true;

        // this.$router.push({ name: 'CardTypeCreate', params: { item } })
        },
        createPlayer() {
            this.pointsUserSelected = null;
            this.pointsUserDialog = true;
        },
        async deleteItemConfirmed(item) {
            this.$store.dispatch('loading/start');

            const gameId = this.gameSelected.id;
            const urlAppend = item.id ? '/' + item.id : '';

            try {
                const url = 'admin/games/statistic/pointsUser'
                const result = await axios
                    .delete(url + urlAppend, { data: { gameId } });

                if (result.status === 200) {
                    this.getRanking();
                    // snackbar/
                    this.$store.dispatch('snackbar/success', this.$t('common.deleteOK'))
                }
            } catch (error) {
            }
            this.$store.dispatch('loading/end');
        },
        async deleteItem(item) {
            if (
                await this.$refs.confirm.open(
                    this.$t('common.confirmTitle'),
                    this.$t('common.confirmMSGDelete')
                )
            ) {
                this.deleteItemConfirmed(item)
            }
        },
        async createExternalCompetition(){
            const game = await this.$refs.createGameCompetition.open();

            if(game){
                this.games.push(game);
                this.gameSelected = game;
            }

        },
        filterTableItems(value, search, item) {
            // console.log("filterTableItems", value);
            let filter = false;
            if (value != null &&
                search != null) {
                if (typeof value === 'string') {
                    if (value === item.created_at || value === item.updated_at) {
                        filter = this.dateHelper.getDateTimeTableFormat(value).includes(search);
                    } else { filter = value.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1 }
                } else if (typeof value === 'number') {
                    if (value == search) {
                        filter = true;
                    } else {
                        filter = (value + '').indexOf(search) !== -1;
                    }
                }
            }

            return filter;
        },
        afterImportPoints(){
            this.getRanking(this.gameSelected.id);
        }
    },
    destroyed() {

    }
}
</script>

<style lang="scss" >
.table-current-user {
 background-color: #46d5ab;
}

</style>
