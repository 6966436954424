import BaseApiServices from "@/services/BaseApiServices";
import axios from "@/plugins/axios";

class CompaniesAPIService extends BaseApiServices{

    #URL_MAIN = 'companies';


    async getCompanies( { signal = null } ) {
        //controller = new AbortController();

        const url = `${this.#URL_MAIN}`;

        const data = await this.getAxiosInstance().get(url, {signal});

        return data;
    }
    async getCompany({ companyId, signal = null }) {

        const url = `${this.#URL_MAIN}/${companyId}`;

        const data = await this.getAxiosInstance().get(url, {signal});

        return data;
    }

}

export default new CompaniesAPIService();
