<template>
    <v-dialog
        v-model="dialog"
        max-width="500px"
        persistent
    >
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <!-- Edited users-->
            <v-card-text>
                <v-form v-model="isValid">
                    <v-container class="py-0">
                        <v-row>

                            <!--                            <v-col
                                cols="12"
                            >
                                <v-autocomplete
                                    v-model="editedItem.company_id.value"
                                    :items="companies"
                                    :label="$t('admin.companyRanking.company')"
                                    item-text="name"
                                    item-value="id"
                                    :rules="[rules.required]"
                                    :disabled="isOnlyView"
                                />

                            </v-col>-->

                            <v-col
                                cols="12"
                                class="mt-0 pt-0"
                            >
                                <v-autocomplete
                                    v-model="editedItem.game_id.value"
                                    :items="games"
                                    :label="$t('admin.companyRanking.game')"
                                    item-text="name"
                                    item-value="id"
                                    :disabled="isOnlyView"
                                    clearable
                                />
                                <!--                                <v-text-field
                                    v-model="editedItem.game_id.value"
                                    class="purple-input"
                                    :label="$t('admin.companyRanking.game')"
                                    :error-messages="editedItem.game_id.error"
                                    :disabled="isOnlyView"
                                    @keyup="editedItem.game_id.error = ''"
                                />-->
                            </v-col>

                            <v-col
                                cols="12"
                                class="mt-0 pt-0"
                            >
                                <v-text-field
                                    v-model="editedItem.title_es.value"
                                    class="purple-input"
                                    :label="$t('admin.companyRanking.title_es')"
                                    :error-messages="editedItem.title_es.error"
                                    :disabled="isOnlyView"
                                    @keyup="editedItem.title_es.error = ''"
                                />
                            </v-col>

                            <v-col
                                cols="12"
                                class="mt-0 pt-0"
                            >
                                <v-text-field
                                    v-model="editedItem.title_en.value"
                                    class="purple-input"
                                    :label="$t('admin.companyRanking.title_en')"
                                    :error-messages="editedItem.title_en.error"
                                    :disabled="isOnlyView"
                                    @keyup="editedItem.title_en.error = ''"
                                />
                            </v-col>

                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer />

                <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                >
                    {{ $t(`common.${ isOnlyView ? 'endProcess' : 'confirmDialogCancel'}`) }}
                </v-btn>
                <v-btn
                    v-if="!isOnlyView"
                    color="blue darken-1"
                    text
                    :disabled="!isValid && !serverCheck"
                    @click="save"
                >
                    {{ $t('common.dialogSave') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from '@/plugins/axios'
import rules from '@/helpers/rulesValidate'
import { mapGetters } from 'vuex'

export default {
    components: { },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        item: {
            type: Object,
            required: false
        },
        urlMain: {
            type: String,
            required: true
        },
        companyId: Number,
        //companies: Array,
        games: Array
    },
    data: function () {
        return {
            rules: {
                ...rules
            },
            editedItem: this.getEditedItem(),
            isValid: true,
            serverCheck: false,
            isOnlyView: false
        }
    },
    computed: {
        formTitle() {
            if (this.item) {
                return this.isOnlyView ? this.$t(`admin.companyRanking.view`)
                    : this.$t(`admin.companyRanking.edit`)
            } else return this.$t(`admin.companyRanking.new`)
        }
    },
    methods: {

        getEditedItem(itemInit = null) {
            const item = itemInit ?? this.item;

            return {
                id: item?.id ?? '',
                /*company_id: {
                    value: item?.company_id ?? '',
                    error: ''
                },*/
                game_id: {
                    value: item?.game_id ?? '',
                    error: ''
                },
                title_es: {
                    value: item?.title_es ?? '',
                    error: ''
                },
                title_en: {
                    value: item?.title_en ?? '',
                    error: ''
                }
            }
        },

        initialize() {

        },
        async save() {
            const formData = new FormData();
            formData.append("company_id", this.companyId);
            formData.append("game_id", this.editedItem.game_id.value ?? '');
            formData.append("title_es", this.editedItem.title_es.value ?? '');
            formData.append("title_en", this.editedItem.title_en.value ?? '');

            let urlAppend = ''
            let method = 'POST'
            if (this.editedItem.id) {
                urlAppend = '/' + this.editedItem.id
                method = 'PATCH'

                formData.append("_method", method);
            }

            /* console.log('********** formData ********')
            console.log(formData) */

            try {
                const result = await axios
                    .post(this.urlMain + urlAppend, formData, {
                        headers: { "Content-Type": "multipart/form-data" }
                    });

                if (result.status === 200 || result.status === 201 || result.status === 204) {
                    this.close()

                    const actionMSG = this.editedItem.id ? 'updatedOK' : 'createdOK'
                    this.$store.dispatch('snackbar/success', this.$t('common.' + actionMSG))
                }
            } catch (error) {
                /* console.log('********** errors ********')
                console.log(error) */

                this.$store.dispatch('snackbar/error', this.$t('common.accionError'))
            }

            this.$emit('update:table')
        },
        close() {
            this.$emit('update:dialog', false)
        }
    }
}
</script>

<style >

</style>
