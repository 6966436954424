<template>
    <v-dialog
        v-model="dialog"
        max-width="500px"
        persistent
    >
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <!-- Edited users-->
            <v-card-text>

                <v-form v-model="isValid">
                    <v-container class="py-0">
                        <div v-if="item">
                            <div class="text-subtitle-1">{{$t('games.ranking.name')}}: <strong> {{ editedItem.name.value }}</strong></div>
                            <div class="text-subtitle-2">{{$t('games.ranking.lastname')}}: <strong> {{ editedItem.lastname.value }}</strong></div>
                        </div>

                        <v-row v-else>
                            <v-col
                                cols="12"
                                sm="12"
                            >
                                <!--                              :disabled="isOnlyView"-->

                                <v-autocomplete
                                    v-model="editedItem.id"
                                    :items="players"
                                    :label="'Usuarios que no tienen puntuación en el juego seleccionado'"
                                    item-text="username"
                                    item-value="id"
                                    :rules="[rules.required]"
                                />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-text-field
                                    v-model="editedItem.points.value"
                                    class="purple-input"
                                    :label="$t('games.ranking.points')"
                                    :error-messages="editedItem.points.error"
                                    :rules="[rules.validNumberOrEmpty]"
                                    :disabled="isOnlyView"
                                    @keyup="editedItem.points.error = ''"
                                />
                            </v-col>

                            <v-col
                                cols="12"
                                class="mt-0 pt-0"
                            >
                                <v-text-field
                                    v-model="editedItem.timeT.value"
                                    class="purple-input"
                                    :label="$t('games.ranking.time')"
                                    :error-messages="editedItem.timeT.error"
                                    :disabled="isOnlyView"
                                    @keyup="editedItem.timeT.error = ''"
                                    :rules="[rules.validNumberOrEmpty]"
                                />
                            </v-col>

                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer />

                <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                >
                    {{ $t(`common.${ isOnlyView ? 'endProcess' : 'confirmDialogCancel'}`) }}
                </v-btn>
                <v-btn
                    v-if="!isOnlyView"
                    color="blue darken-1"
                    text
                    :disabled="!isValid && !serverCheck"
                    @click="save"
                >
                    {{ $t('common.dialogSave') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from '@/plugins/axios'
import rules from '@/helpers/rulesValidate'
import { mapGetters } from 'vuex'

export default {
    components: { },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        item: {
            type: Object,
            required: false
        },
        companyId: {
            type: Number,
            required: true
        },
        gameId: {
            type: Number,
            required: true
        }
    },
    data: function () {
        return {
            rules: {
                ...rules
            },
            editedItem: this.getEditedItem(),
            isValid: true,
            serverCheck: false,
            isOnlyView: false,
            players: []
        }
    },
    computed: {
        formTitle() {
            return 'Editar Puntuación'
        }
        // ...mapGetters(['role', 'cities'])
    },
    created() {
        if (!this.item) {
            this.fetchPlayersNotPointsInGame();
        }
    },
    methods: {
        async fetchPlayersNotPointsInGame() {
            try {
                const url = `admin/games/statistic/pointsUser/${this.companyId}/usersNotPointsIn/${this.gameId}`;

                const result = await axios
                    .get(url);

                this.players = result.data.players;
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        getEditedItem(itemInit = null) {
            const item = itemInit ?? this.item;

            return {
                id: item?.id ?? '',
                name: {
                    value: item?.name ?? '',
                    error: ''
                },
                lastname: {
                    value: item?.lastname ?? '',
                    error: ''
                },
                points: {
                    value: item?.points ?? '',
                    error: ''
                },
                timeT: {
                    value: item?.timeT ?? '',
                    error: ''
                }
            }
        },

        initialize() {

        },
        async save() {
            const formData = new FormData();
            formData.append("userId", this.editedItem.id);
            formData.append("gameId", this.gameId);
            formData.append("points", this.editedItem.points.value ?? '');
            formData.append("time", this.editedItem.timeT.value ?? '');

            let urlAppend = '/' + this.editedItem.id;
            let method = 'PATCH';
            formData.append("_method", method);

            try {
                const url = `admin/games/statistic/pointsUser`;

                const result = await axios
                    .post(url + urlAppend, formData, {
                        headers: { "Content-Type": "multipart/form-data" }
                    });

                if (result.status === 200 || result.status === 201 || result.status === 204) {
                    this.close()

                    const actionMSG = this.editedItem.id ? 'updatedOK' : 'createdOK'
                    this.$store.dispatch('snackbar/success', this.$t('common.' + actionMSG))
                }
            } catch (error) {
                /* console.log('********** errors ********')
                console.log(error) */

                this.$store.dispatch('snackbar/error', this.$t('common.accionError'))
            }

            this.$emit('update:table')
        },
        close() {
            this.$emit('update:dialog', false)
        }
    }
}
</script>

<style >

</style>
