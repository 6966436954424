<template>
    <v-row>
        <v-col cols="12">
            <base-card>
                <v-card-title>
                    <div class="d-flex justify-space-between flex-wrap">
                        <v-btn
                            class="ma-2"
                            dark
                            color="primary"
                            @click="createGame"
                        >
                            <v-icon>mdi-plus</v-icon>
                            {{$t('admin.games.add') }}
                        </v-btn>
                    </div>
                </v-card-title>
                <v-card-title>
                    {{$t('admin.games.titleList') }}
                    <v-spacer />


                    <v-text-field
                        v-model="search"
                        :append-icon="!search ? 'mdi-magnify' : undefined"
                        clearable
                        :label="$t('common.search')"
                        single-line
                        hide-details
                    />
                </v-card-title>


                <v-data-table
                    :options.sync="options"
                    :headers="headers"
                    :footer-props="{ 'items-per-page-options': [5, 10, 30, 50, -1] }"
                    :items="items"
                    class="elevation-1"
                    :sort-by="sortby"
                    :multi-sort="true"
                    :search="search"
                    :custom-filter="filterTableItems"
                    @click:row="editItem"
                >
                    <template #item.id="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>

                    <template #item.type.name="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>
                    <template #item.teams_count="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>
                    <template #item.active="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>

                    <template #item.name="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>
                    <template #item.description="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>

                    <template v-slot:item.start_at="{ value }">
                        <div :inner-html.prop="dateHelper.getDateTimeTableFormat(value)" />
                    </template>

                    <template v-slot:item.ends_at="{ value }">
                        <div :inner-html.prop="dateHelper.getDateTimeTableFormat(value)" />
                    </template>

                    <template v-slot:item.created_at="{ value }">
                        <div :inner-html.prop="dateHelper.getDateTimeTableFormat(value)" />
                    </template>
                    <template #item.updated_at="{ value }">
                        <div :inner-html.prop="dateHelper.getDateTimeTableFormat(value)" />
                    </template>


                    <template v-slot:item.actions="{ item }">
                        <v-tooltip
                            v-if="hasInfo(item)"
                            top
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    small
                                    class="mr-2"

                                    v-bind="attrs"
                                    @click.stop="infoItem(item)"
                                    v-on="on"
                                >
                                    mdi-information
                                </v-icon>
                            </template>

                            <span>{{ $t('common.infoTitle') }}</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    small
                                    class="mr-2"

                                    v-bind="attrs"
                                    @click.stop="editItem(item)"
                                    v-on="on"
                                >
                                    mdi-pencil
                                </v-icon>
                            </template>

                            <span>{{ $t('common.edit') }}</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    small
                                    v-bind="attrs"
                                    @click.stop="deleteItem(item)"
                                    v-on="on"
                                >
                                    mdi-delete
                                </v-icon>
                            </template>

                            <span>{{ $t('common.delete') }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:no-data>
                        <v-btn
                            color="primary"
                            @click="fetchGames"
                        >
                            {{ $t('admin.common.resetData') }}
                        </v-btn>
                    </template>
                </v-data-table>
            </base-card>

            <confirm-dialog
                ref="confirm"
            />
        </v-col>
    </v-row>
</template>

<script>
import axios from '@/plugins/axios'
import ConfirmDialog from '@/components/common/ConfirmDialog'
import { debounce } from "debounce";

import GamesAPIService from "@/services/GamesAPIService";

export default {
    components: { /* RoleCreate, */ ConfirmDialog },
    props:{
        companyId: {
            type: Number,
            required: true
        }
    },
    data: function () {
        return {
            urlMain: 'admin/games/games',
            /* dialog: false, */
            headers: [
                {
                    text: 'Id',
                    align: 'start',
                    sortable: true,
                    value: 'id'
                },
                {
                    text: this.$t('admin.games.teamsCount'),
                    value: 'teams_count'
                },
                {
                    text: 'Activo',
                    value: 'active'
                },
                {
                    text: this.$t('admin.games.type'),
                    value: 'type.name'
                },
                {
                    text: this.$t('common.name'),
                    value: 'name'
                },
                {
                    text: this.$t('admin.games.start_at'),
                    value: 'start_at'
                },
                {
                    text: this.$t('admin.games.ends_at'),
                    value: 'ends_at'
                },
                {
                    text: this.$t('common.description'),
                    value: 'description'
                },
                {
                    text: this.$t('admin.common.createdAt'),
                    value: 'created_at'
                },
                {
                    text: this.$t('admin.common.updatedAt'),
                    value: 'updated_at'
                },
                // { text: "Role", value: "role_id" },
                {
                    text: this.$t('admin.common.actions'),
                    value: 'actions',
                    sortable: false
                }
            ],
            search: '',
            items: [],
            itemSelected: null,
            sortby: ['id'],
            options: {
                itemsPerPage: 10
            }
        }
    },
    watch: {
        dialog(val) {
            val || this.close()
        }
    },
    /* computed: {
      ...mapGetters(['role'])
  }, */

    created() {
        this.fetchGames();
    },

    mounted() {

    },

    methods: {
        hasInfo(item) {
            return this.gameTypeHelper.hasInfo(item);
        },
        async fetchGames() {
            try {
                const companyId = this.companyId;
                const controller = new AbortController();
                const signal = controller.signal;

                const games = await GamesAPIService.getGamesFromCompany({companyId, signal});

                this.initialize(games.data)
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        initialize($data) {
            let itemsFiltered = $data.games ?? [];
            let type = this.$route.query.type;
            if (!isNaN(type)) {
                type = +type;
                itemsFiltered = itemsFiltered.filter((item) => item.game_type_id === type);
            }

            this.items = itemsFiltered;

            // this.pagination.totalItems = $data?.meta?.page?.total ?? 0;
        },
        createGame() {
            this.$router.push('/app/admin/games/create/new?company=' + this.companyId);
        },
        /* setRole($data) {
      this.itemsRoles = $data;
  }, */
        infoItem(item) {
            this.$router.push({ name: 'GameInfo', params: { item, id: item.id + '' } })
        // this.$router.push({ name: 'GameCreate', params: { id: item.id } })
        },
        editItem(item) {
            // this.itemSelected = item;
            // this.dialog = true;

            this.$router.push({ name: 'GameCreate', params: { item, id: item.id + '' } })
            // this.$router.push({ name: 'GameCreate', params: { id: item.id } })
        },
        async deleteItemConfirmed(item) {
            const urlAppend = item.id ? '/' + item.id : '';

            try {
                const result = await axios
                    .delete(this.urlMain + urlAppend);

                if (result.status === 200) {
                    this.fetchGames();
                    // snackbar/
                    this.$store.dispatch('snackbar/success', this.$t('common.deleteOK'))
                }
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data.error) // "invalid_credentials"
                    console.log(error.response.status)
                    console.log(error.response.headers)
                }
            }
            this.$store.dispatch('loading/end')
        },
        async deleteItem(item) {
            if (
                await this.$refs.confirm.open(
                    this.$t('common.confirmTitle'),
                    this.$t('common.confirmMSGDelete')
                )
            ) {
                this.deleteItemConfirmed(item)
            }
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.itemSelected = null
            })
        },
        filterTableItems(value, search, item) {
            // console.log("filterTableItems", value);
            let filter = false;
            if (value != null &&
                search != null) {
                if (typeof value === 'string') {
                    if (value === item.created_at || value === item.updated_at) {
                        filter = this.dateHelper.getDateTimeTableFormat(value).includes(search);
                    } else { filter = value.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1 }
                } else if (typeof value === 'number') {
                    if (value == search) {
                        filter = true;
                    } else {
                        filter = (value + '').indexOf(search) !== -1;
                    }
                }
            }

            return filter;
        }
    },
    destroyed() {
        // GamesAPIService.getGamesAbort();
    }

}
</script>
