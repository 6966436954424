import BaseApiServices from "@/services/BaseApiServices";

class GamesAPIService extends BaseApiServices{

  #URL_MAIN = 'admin/games/games';

  async getGames({ signal = null }) {

      const url = `${this.#URL_MAIN}`;

      const data = await this.getAxiosInstance().get(url, {signal});

      return data;
  }
  async getGamesFromCompany( { companyId, includeWithoutCompany = false, signal = null }) {


      const appendURL = includeWithoutCompany ? '?include=withoutCompany' : '';

      const url = `admin/games/gamesFromCompany/${ companyId }${ appendURL }`;

      const data = await this.getAxiosInstance().get(url, {signal});

      return data;
  }
  async getGame( { gameId, signal = null }) {

      const url = `${this.#URL_MAIN}/${gameId}`;

      const data = await this.getAxiosInstance().get(url, {signal});

      return data;
  }

}

export default new GamesAPIService();
