<template>
    <v-dialog
        v-model="dialog"
        max-width="600px"
        @keydown.esc="cancel"
        @keydown.enter.stop=""
    >
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
                <v-form v-model="isValid">

                    <v-row>
                        <v-col
                            cols="12"
                        >
                            <v-select
                                v-model="editedItem.game_type_id.value"
                                :error-messages="editedItem.game_type_id.error"
                                :items="gameTypes"
                                attach
                                :label="$t('admin.games.type')"
                                item-text="name"
                                item-value="id"
                                :rules="[rules.required]"
                                :disabled="isOnlyView || !!item"
                                @change="editedItem.game_type_id.error = ''"
                            />
                        </v-col>

                        <!--                    <v-col
                        cols="12"
                        md="3"
                        lg="2"
                        sm="4"
                    >
                        <v-text-field
                            v-model="editedItem.teams_count.value"
                            class="purple-input"
                            :label="$t('admin.games.teamsCount')"
                            :error-messages="editedItem.teams_count.error"
                            :rules="[rules.validNumber]"
                            :disabled="isOnlyView || !!item"
                            @keyup="editedItem.teams_count.error = ''"
                        />
                    </v-col>-->

                        <v-col
                            cols="12"
                        >
                            <v-text-field
                                v-model="editedItem.name.value"
                                class="purple-input"
                                :label="$t('common.name')"
                                :error-messages="editedItem.name.error"
                                :disabled="isOnlyView"
                                @keyup="editedItem.name.error = ''"
                            />
                        </v-col>

                        <v-col
                            cols="12"
                        >
                            <v-text-field
                                v-model="editedItem.description.value"
                                class="purple-input"
                                :label="$t('common.description')"
                                :error-messages="editedItem.description.error"
                                :disabled="isOnlyView"
                                @keyup="editedItem.description.error = ''"
                            />
                        </v-col>

                    </v-row>
                </v-form>
            </v-card-text>

            <v-card-actions class="flex-wrap justify-end">
                <v-btn
                    color="blue darken-1"
                    text
                    @click="cancel"
                >
                    {{ $t(`common.${isOnlyView || item ? 'endProcess' : 'confirmDialogCancel'}`) }}
                </v-btn>
                <v-btn
                    v-if="!isOnlyView"
                    color="blue darken-1"
                    text
                    :disabled="!isValid && !serverCheck"
                    @click="save"
                >
                    {{ $t('common.dialogSave') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from '@/plugins/axios'
import rules from '@/helpers/rulesValidate'

export default {
    name: "createGameCompetition",
    props:{
        companyId: {
            type: Number,
            required: true
        }
    },
    data: function () {
        return {
            urlMain: 'admin/games/games',
            rules: {
                ...rules
            },
            item: null,
            editedItem: this.getEditedItem(),
            isValid: true,
            serverCheck: false,
            isOnlyView: false,
            gameTypes: [],
            dialog: false,
            resolve: null,
            reject: null
        }
    },
    computed: {
        formTitle() {
            return 'Nueva Competición';
        }
    // ...mapGetters(['role', 'cities'])
    },
    async created() {
        if (this.item) {
            this.editedItem = this.getEditedItem(this.item);
        }

        this.fetchGameTypes();
    },
    mounted() {
    },
    methods: {
        open(item = null) {
            this.item = item;

            this.dialog = true;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        getEditedItem(itemInit = null) {
            const item = itemInit ?? null;

            var empty = !item

            return {
                id: item?.id ?? '',
                game_type_id: {
                    value: empty ? '' : +item.game_type_id,
                    error: ''
                },
                /*teams_count: {
                    value: empty ? '' : +item.teams_count,
                    error: ''
                },*/
                name: {
                    value: item?.name ?? '',
                    error: ''
                },
                description: {
                    value: item?.description ?? '',
                    error: ''
                }
            }
        },
        async save() {
            const formData = new FormData();
            formData.append("company_id", this.companyId);
            formData.append("game_type_id", this.editedItem.game_type_id.value);
            formData.append("teams_count", /*this.editedItem.teams_count.value ??*/ 1);
            formData.append("active", 1);
            formData.append("name", this.editedItem.name.value ?? '');
            formData.append("description", this.editedItem.description.value ?? '');


            let urlAppend = ''
            let method = 'POST'
            if (this.editedItem.id) {
                urlAppend = '/' + this.editedItem.id
                method = 'PATCH'

                formData.append("_method", method);
            }

            try {
                const result = await axios
                    .post(this.urlMain + urlAppend, formData, {
                        headers: {"Content-Type": "multipart/form-data"}
                    });

                if (result.status === 200 || result.status === 201 || result.status === 204) {
                    this.agree(result.data.game);

                    const actionMSG = this.editedItem.id ? 'updatedOK' : 'createdOK'
                    this.$store.dispatch('snackbar/success', this.$t('common.' + actionMSG))
                }
            } catch (error) {

                if (error?.response?.status === 422) {
                    if (error.response?.data?.errors?.length) {
                        error.response.data.errors.forEach((e) => {
                            if (e.source.pointer === '/data/attributes/title') {
                                this.editedItem.title.error = e.detail
                            }
                            if (e.source.pointer === '/data/attributes/body') {
                                this.editedItem.body.error = e.detail
                            }
                        })
                    }
                }

                this.$store.dispatch('snackbar/error', this.$t('common.accionError'))
            }
        },
        async fetchGameTypes() {
            try {
                const urlTypes = 'admin/games/typesExternal';

                const gameTypes = await axios
                    .get(urlTypes)

                this.gameTypes = gameTypes.data.gameTypes;

                if(this.gameTypes.length){
                    this.editedItem.game_type_id.value = this.gameTypes[0].id;
                }
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        agree(game) {
            this.resolve(game);
            this.dialog = false;
        },
        cancel() {
            this.resolve(false);
            this.dialog = false;
        }
    }
}
</script>

<style>

</style>
